/**
 * Map class containing all set up and handling for a given map block
 */
class Map {
  constructor(mapBlock) {
    this.stateId = localStorage.getItem('map_state_id') ?? null
    this.mapAreas = mapBlock.querySelectorAll('.block-map__map-svg path');
    this.placeholder = mapBlock.querySelector('.block-map__descriptions .placeholder')
    this.noMatch = mapBlock.querySelector('.block-map__descriptions .no-match');
    this.descriptions = mapBlock.querySelectorAll('.block-map__descriptions .map-description');

    this.selectMapArea(this.stateId)
    this.showDescriptions(this.stateId)

    this.mapAreas.forEach( mapArea => {
      mapArea.addEventListener('click',event => this.areaClicked(event.target.id));
    })
  }

  /**
   * Handles area click event:
   * - stores clicked mapAreas ID to localStorage
   * - runs `selectMapArea()`
   * - runs `showDescriptions()`
   */
  areaClicked(stateId) {
    localStorage.setItem('map_state_id', stateId)
    this.selectMapArea(stateId)
    this.showDescriptions( stateId)
  }

  // Unselects currently selected area and selects just clicked area.
  selectMapArea(stateId) {
    this.mapAreas.forEach(area => {
      if (area.id === stateId) {
        area.classList.add('selected')
      } else {
        area.classList.remove('selected')
      }
    })
  }

  /**
   * Iterate over all descriptions and add class "shown" to each matching description.
   * Add "shown" to placeholder or noMatch texts if necessary.
    */
  showDescriptions(stateId){
    if (!stateId) {
      this.placeholder.classList.add('shown')
      this.descriptions.forEach(description => description.classList.remove('shown'))
    } else {
      this.placeholder.classList.remove('shown')
      this.noMatch.classList.add('shown');

      this.descriptions.forEach(description => {
        if (description.classList.contains('state-' + stateId)) {
          description.classList.add('shown')
          this.noMatch.classList.remove('shown');
        } else {
          description.classList.remove('shown')
        }
      })
    }
  }
}

// Query for all map blocks on the page and instantiate a Map class for each one of them.
const maps = [...document.querySelectorAll('.block--type-map')].map( block => {
  return new Map(block);
});
